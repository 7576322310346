import api from "./api";

var queryString = (params) => {
  return Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
};

export const getActivities = (params) => {
  return api.get("/v1/crm/activities?" + queryString(params));
};
export const postActivity = (params) => {
  console.log('params: ', params);  
  return api.post("/v1/crm/activities", params);
};